<template>
  <scrollable-container :class="[$style.scrollableContainer]">
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />
    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <table :class="$style.table">
        <thead>
          <tr :class="$style.header">
            <td
              v-for="column in columns"
              :key="column.name"
              @click="changeSort(column)"
            >
              <div :class="$style.tdInline">
                {{ column.name }}
              </div>
              <img
                src="@/assets/images/icons/table/sort-arrow.svg"
                :class="[
                  $style.tdInline,
                  $style.arrow,
                  {
                    [$style.asc]: column.sort == 2,
                    [$style.hidden]: column.sort == 0,
                  },
                ]"
              >
            </td>
          </tr>
        </thead>
        <tr :class="$style.total">
          <td>Всего</td>
          <td>{{ convertSecondsToDays(getTotal[0]) }}</td>
          <td>{{ convertSecondsToDays(getTotal[1]) }}</td>
          <td>{{ convertSecondsToDays(getTotal[2]) }}</td>
          <td>{{ getTotal[3].toFixed(2) }}</td>
          <td>{{ convertSecondsToDays(getTotal[4]) }}</td>
          <td>{{ convertSecondsToDays(getTotal[5]) }}</td>
          <td>{{ getTotal[6] }}</td>
          <td>{{ convertSecondsToDays(getTotal[7]) }}</td>
        </tr>

        <tbody
          v-for="(department, index) in sortedDeps"
          :key="index"
        >
          <tr
            v-show="department.isVisible"
            :class="[
              $style.item,
              {
                [$style.red]:
                  !isFolder(department) &&
                  (department.reportData.overwork < 0 ||
                    department.reportData.outOfWork > 0),
              },
            ]"
            @contextmenu.prevent="showMenu($event, department)"
          >
            <td
              :style="indent(department.depth)"
              :class="[{ [$style.department]: isFolder(department) }, { [$style.user]: !isFolder(department) }]"
              @click="expand(department)"
            >
              <div
                v-if="isFolder(department)"
                :class="[
                  $style.expandButton,
                  { [$style.expanded]: department.isExpanded },
                ]"
              />
              <img
                v-if="isFolder(department)"
                :class="$style.iconFolder"
                src="@/assets/images/icons/computer/folder.svg"
              >

              <div v-if="isFolder(department)">
                {{ department.reportData.name }}
              </div>

              <div v-if="!isFolder(department)">
                {{ department.nameUser }}
              </div>
            </td>

            <td>{{ convertSecondsToDays(department.reportData.workTime) }}</td>
            <td>
              {{ convertSecondsToDays(department.reportData.activeTime) }}
            </td>
            <td>{{ convertSecondsToDays(department.reportData.idleTime) }}</td>
            <td>{{ department.reportData.activePercent.toFixed(2) }}</td>
            <td>{{ convertSecondsToDays(department.reportData.workPlan) }}</td>
            <td>{{ convertSecondsToDays(department.reportData.overwork) }}</td>
            <td>{{ department.reportData.outOfWork }}</td>
            <td>
              {{ convertSecondsToDays(department.reportData.holidayWork) }}
            </td>
          </tr>
        </tbody>
      </table>
    </container>
    <container
      v-show="state === reportEnum.tableReady"
      :class="$style.notScrollableContent"
    >
      <tabset
        :tabs="tabs"
        :opened="opened"
        :hidden-text="hiddenText"
        :tab-style="tabStyle"
        @tabClick="openNewTab"
        @toggle="toggle"
      />
      <barchart
        v-show="tabs[0].active && opened"
        :bars="overworkMax"
        :rect-style="rectStyleOverworkMax"
        :text-style="textStyleOverworkMax"
        :is-convert-needed="true"
      />
      <barchart
        v-show="tabs[1].active && opened"
        :bars="overworkMin"
        :rect-style="rectStyleOverworkMin"
        :text-style="textStyleOverworkMin"
        :is-convert-needed="true"
      />
      <barchart
        v-show="tabs[2].active && opened"
        :bars="outWorkMax"
        :rect-style="rectStyleOutOfWork"
        :text-style="textStyleOutOfWork"
        :is-convert-needed="false"
      />
    </container>
    <context-menu
      v-show="isMenuVisible"
      id="contextmenu"
      :user-id="selectedUser"
      :mouse-x="mouseX"
      :mouse-y="mouseY"
      :date-from="dateRange.dateFrom"
      :date-to="dateRange.dateTo"
      :is-worktime-only="isWorktimeOnly"
      :pages="pages"
    />
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import Barchart from '@/components/common/bars/top-bar-chart.vue';
import { getPCSumTable } from '@/api/methods/reports/users/index';
import { convertSecondsToDays } from '@/helpers/format-date';
import ContextMenu from '@/components/common/contextmenu/custom-contextmenu.vue';
import Loader from '@/components/common/blocks/loader.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    Barchart,
    Tabset,
    Placeholder,
    ContextMenu,
    Loader,
  },
  data: () => ({
    name: 'Суммарный отчет по рабочему времени',
    exportArray: [],
    opened: true,
    reportTree: [],
    selectedUser: null,
    mouseX: null,
    mouseY: null,
    isMenuVisible: false,
    pages: [
      {
        name: 'worktime',
        text: 'Табель рабочего времени',
      },
      {
        name: 'productivity',
        text: 'Отчет по продуктивности',
      },
    ],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    numberOfTopComputers: 5,
    tableArray: [],
    columns: [
      { name: 'Пользователь', sort: 2, field: 'name' },
      { name: 'Включен', sort: 0, field: 'workTime' },
      { name: 'Активность', sort: 0, field: 'activeTime' },
      { name: 'Простой', sort: 0, field: 'idleTime' },
      { name: '% акт-ти', sort: 0, field: 'activePercent' },
      { name: 'По плану', sort: 0, field: 'workPlan' },
      { name: 'Переработка', sort: 0, field: 'overwork' },
      { name: 'Прогулы', sort: 0, field: 'outOfWork' },
      { name: 'В выходные', sort: 0, field: 'holidayWork' },
    ],
    text: 'Для построения отчета нажмите кнопку',
    total: {
      work: 0,
      active: 0,
      idle: 0,
      percent: 0,
      plan: 0,
      overwork: 0,
      out: 0,
      holiday: 0,
    },
    overworkMax: [],
    overworkMin: [],
    outWorkMax: [],
    tabStyle: {
      padding: '14px 20px 17px 20px',
      fontSize: '16px',
    },
    rectStyleOverworkMin: {
      height: '17px',
      fill: '#ffb0b0',
    },
    rectStyleOverworkMax: {
      height: '17px',
      fill: '#7CD890',
    },
    rectStyleOutOfWork: {
      height: '17px',
      fill: '#94C2E0',
    },
    textStyleOverworkMin: {
      fill: '$red',
      fontSize: '12px',
      letterSpacing: '-0.24px',
    },
    textStyleOverworkMax: {
      fill: '$green',
      fontSize: '12px',
      letterSpacing: '-0.24px',
    },
    textStyleOutOfWork: {
      fill: '#1970A7',
      fontSize: '12px',
      letterSpacing: '-0.24px',
    },
    hiddenText: 'ТОП 5 - показать',
    tabs: [
      {
        title: 'ТОП 5 по переработке',
        active: true,
      },
      {
        title: 'ТОП 5 по недоработке',
        active: false,
      },
      {
        title: 'ТОП 5 по прогулам',
        active: false,
      },
    ],
  }),
  computed: {
    getTotal() {
      return Object.values(this.total);
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];
      let treeCopy = JSON.parse(JSON.stringify(tree));

      treeCopy = this.cutUncheckedNodes(treeCopy);

      return treeCopy;
    },
    sortedColumn() {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const s = this.columns[index].sort;

      return { index, sort: s };
    },
    sortedDeps() {
      const calc = this.reportTree.slice(0).sort(this.compare);

      calc.forEach((element) => {
        if (element.type === 'folder') this.sortNodes(element.children);
      });

      this.updateTableArray(calc);

      if (this.exportArray.length === 0 && this.tableArray.length > 0) this.makeExportArray();
      return this.tableArray;
    },
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    makeExportArray() {
      this.tableArray.forEach((item) => {
        const resObj = {};
        if (item.type !== 'folder') {
          for (let j = 0; j < this.columns.length; j++) {
            const { field } = this.columns[j];
            const res = item.reportData[field];
            if (field === 'name' || field === 'outOfWork') resObj[this.columns[j].name] = res;
            else if (field === 'activePercent') resObj[this.columns[j].name] = res.toFixed(2);
            else resObj[this.columns[j].name] = convertSecondsToDays(res);
          }
          this.exportArray.push(resObj);
        }
      });

      this.$store.commit('exportData/setConsolidated', { name: this.name, data: this.exportArray });
    },
    updateTableArray(calc) {
      this.tableArray = [];
      calc.forEach((node) => this.generateArrayFromTree(node, 1));
    },
    changeVisibility(item, bool) {
      item.isVisible = bool;
      if (item.type === 'folder' && item.isExpanded === bool && item.children) {
        item.children.forEach((child) => this.changeVisibility(child, bool));
      }
    },
    expand(item) {
      if (item.type === 'folder') {
        item.isExpanded = !item.isExpanded;

        if (item.children) {
          item.children.forEach((child) => this.changeVisibility(child, item.isExpanded));
        }
      }
    },
    isFolder(node) {
      if (node.type === 'folder') return true;
      return false;
    },
    sortNodes(nodes) {
      nodes.sort(this.compare);

      nodes.forEach((node) => {
        if (node.type === 'folder') this.sortNodes(node.children);
      });
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    cutUncheckedNodes(tree) {
      const childrenList = [];

      if (tree) {
        tree.forEach((node) => {
          if (node.checked) childrenList.push(node);
          else if (node.type === 'folder' && node.children) {
            node.children = this.cutUncheckedNodes(node.children);

            if (node.children.length > 0) childrenList.push(node);
          }
        });
      }

      return childrenList;
    },
    putInTopList(value, topComputers, name) {
      for (let i = this.numberOfTopComputers - 1; i >= 0; i--) {
        if (
          Math.abs(value) >= topComputers[i].time
          && (i === 0 || Math.abs(value) <= topComputers[i - 1].time)
        ) {
          topComputers.splice(i, 0, {
            computer: name,
            time: Math.abs(value),
          });

          if (topComputers.length > this.numberOfTopComputers) topComputers.splice(this.numberOfTopComputers);

          break;
        }
      }
    },
    generateTopLists(node) {
      if (node.type === 'computer') {
        if (node.reportData.overwork > 0) {
          this.putInTopList(
            node.reportData.overwork,
            this.overworkMax,
            node.reportData.name,
          );
        } else if (node.reportData.overwork < 0) {
          this.putInTopList(
            node.reportData.overwork,
            this.overworkMin,
            node.reportData.name,
          );
        }

        this.putInTopList(
          node.reportData.outOfWork,
          this.outWorkMax,
          node.reportData.name,
        );
      } else {
        node.children.forEach((child) => this.generateTopLists(child));
      }
    },
    generateArrayFromTree(node, depth) {
      node.depth = depth;

      this.tableArray.push(node);

      if (node.type === 'folder') {
        node.children.forEach((child) => this.generateArrayFromTree(child, depth + 1));
      }
    },
    checkNullBars(topBarsList) {
      topBarsList.forEach((bar) => {
        if (bar.computer === null) topBarsList.splice(topBarsList.indexOf(bar));
      });
    },
    fillWithNull(arrayName) {
      arrayName = Array(this.numberOfTopComputers).fill({
        computer: null,
        time: 0,
      });
      return arrayName;
    },
    async buildReport() {
      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersTree
      ) {
        this.state = this.reportEnum.tableLoading;
        await this.fetch();
      }
      return this.reportTree;
    },
    async fetch() {
      this.reportTree = [];
      this.exportArray = [];
      let workTimeOnly = 0;

      if (this.isWorktimeOnly) workTimeOnly = 1;

      this.promise = await getPCSumTable(
        this.computersTree,
        this.dateRange.dateFrom,
        this.dateRange.dateTo,
        workTimeOnly,
      ).then(({ data }) => {
        if (data) {
          this.reportTree = data.result.computersTree;

          Object.keys(this.total).forEach((key) => {
            this.total[key] = 0;
          });

          this.reportTree.forEach((node) => {
            this.total.work += node.reportData.workTime;
            this.total.active += node.reportData.activeTime;
            this.total.idle += node.reportData.idleTime;
            this.total.percent += parseFloat(
              (node.reportData.activePercent / this.reportTree.length).toFixed(
                2,
              ),
            );
            this.total.plan += node.reportData.workPlan;
            this.total.overwork += node.reportData.overwork;
            this.total.out += node.reportData.outOfWork;
            this.total.holiday += node.reportData.holidayWork;
          });

          this.tableArray = [];

          this.overworkMax = this.fillWithNull(this.overworkMax);
          this.overworkMin = this.fillWithNull(this.overworkMin);
          this.outWorkMax = this.fillWithNull(this.outWorkMax);

          this.reportTree.forEach((node) => this.generateTopLists(node));

          this.checkNullBars(this.overworkMax);
          this.checkNullBars(this.overworkMin);
          this.checkNullBars(this.outWorkMax);

          this.state = this.reportEnum.tableReady;
        } else {
          this.state = this.reportEnum.requestAwaiting;
        }
      });
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
    toggle() {
      this.opened = !this.opened;
    },
    showMenu(event, user) {
      if (!this.isFolder(user)) {
        this.selectedUser = user.id;
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.isMenuVisible = true;

        window.addEventListener('click', (e) => {
          if (!document.getElementById('contextmenu').contains(e.target)) {
            this.isMenuVisible = false;
            window.removeEventListener('click', e);
          }
        });
      }
    },
    indent(depth) {
      return {
        paddingLeft: `${depth * 10}px`,
      };
    },
    compare(a, b) {
      const { index } = this.sortedColumn;
      const key = this.columns[index].field;

      a = a.reportData[key];
      b = b.reportData[key];

      if (index === 0 && a && b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.sortedColumn.sort === 1) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.sortedColumn.sort === 2) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    changeSort(column) {
      const { sort } = column;

      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconFolder {
  padding-right: 5px;
}

.notScrollableContent {
  margin: 20px 8px 0 0;
  padding: 0 12px 20px 20px;
  overflow: unset;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: pointer;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.total {
  height: 55px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: $black;
    padding: 19px 4px 0px 5px;
  }
}

.item {
  height: 29px;
  cursor: pointer;
  border-bottom: 0.2px solid #eee;

  &:hover {
    background: $light-gray;
  }

  &.red {
    background-color: #ffb0b0;

    &:hover {
      background-color: $red;
    }
  }

  td {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 0px 5px;

    &.user {
      font-size: 14px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 7px;
      padding-right: 4px;
      padding-bottom: 7px;
    }

    &.department {
      font-weight: 500;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      padding: 7px 4px 7px 1px;
      display: flex;
      position: relative;
      border-right: 0px;
    }
  }
}

.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 1;
  margin-left: -17px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
</style>
