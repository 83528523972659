<template>
  <g :class="$style.bar">
    <rect :width="barLength(length) + '%'" :style="rectStyle" />
    <text x="20" :y="y" :style="textStyle">
      {{ text }}
    </text>
  </g>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    rectStyle: {
      type: Object,
      required: true,
    },
    textStyle: {
      type: Object,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    time: {
      required: false,
      default: '',
    },
  },
  computed: {
    text() {
      if (this.title) {
        return `${this.title}: ${this.length}`;
      }
      if (this.time) {
        return this.time;
      }
      return this.length;
    },
  },
  methods: {
    barLength(length) {
      const percent = (length * 100) / this.max;
      return percent;
    },
  },
};
</script>

  <style lang="scss" module>
.bar {
  cursor: pointer;
}
</style>
