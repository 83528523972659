import axios from '@/api/axios';
import { getReportData } from '@/api/methods/reports/reports';

export function getPCSumTable(tree, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/users/consolidated?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersTree: tree };

  return getReportData(link, object);
}

export function getAppsTime(tree, dateFrom, dateTo, worktimeOnly, apps) {
  const link = `/reports/users/appsTime?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersTree: tree, selectedApps: apps };

  return getReportData(link, object);
}

export function getWorktimeTable(array, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/users/worktime?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getHeatMap(array, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/users/heatMap?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getViolations(array, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/users/violations?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getActivityFeed(array, dateFrom, dateTo) {
  const link = `/reports/users/activityFeed?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getProductivityReport(array, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/users/productivityReport?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}

export function getAppsList() {
  const result = axios.post(
    '/reports/users/appsList',
  );
  return result;
}
