<template>
  <div :class="$style.line">
    <div
      v-for="(bar, index) in bars"
      :id="index"
      :key="bar.computer + index"
      :class="$style.container"
    >
      <div :class="$style.legend">
        {{ bar.computer }}
      </div>

      <div :class="$style.chartContainer">
        <svg :class="$style.chart">
          <bar
            :length="bar.time"
            :max="maxtime"
            :rectStyle="rectStyle"
            :textStyle="textStyle"
            :y="13"
            :time="convertToHumanTime(bar)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import bar from '@/components/common/bars/bar.vue';
import { convertSecondsToDays } from '@/helpers/format-date';

export default {
  components: {
    bar,
  },
  props: {
    bars: {
      type: Array,
      required: true,
    },
    rectStyle: {
      type: Object,
      required: true,
    },
    textStyle: {
      type: Object,
      required: true,
    },
    isConvertNeeded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    maxtime() {
      const maxtime = Math.max(...this.bars.map((o) => o.time));
      return maxtime;
    },
  },
  methods: {
    convertToHumanTime(currentBar) {
      if (this.isConvertNeeded) return convertSecondsToDays(Math.abs(currentBar.time));
      if (currentBar.site) return currentBar.site;
      return currentBar.time;
    },
  },
};
</script>

<style lang="scss" module>
.chart {
  height: 20px;
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
  height: 23px;
}

.chartContainer {
  flex-grow: 1;
  overflow: auto;
}

.legend {
  display: flex;
  width: 200px;
  height: 20px;
  font-size: 14px;
  margin-left: 25px;
  margin-bottom: 8px;
  letter-spacing: -0.24px;
  font-weight: 400;
  cursor: pointer;
  color: $black;
}

.line {
  justify-content: space-between;
  margin: 20px 8px 10px 0;
  overflow: auto;
  padding-right: 12px;
}
</style>
