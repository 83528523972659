<template>
  <ul :class="$style.tabs">
    <li
      v-for="(tab, index) in tabs"
      v-show="opened"
      :key="index"
      :class="[
        $style.linkContainer,
        {
          [$style.active]: tab.active == true,
        },
      ]"
      @click="$emit('tabClick', index)"
    >
      <a :class="$style.link" :style="tabStyle">
        {{ tab.title }}
      </a>
    </li>
    <li v-show="!opened" :class="$style.hidden" @click="toggle">
      {{ hiddenText }}
    </li>
    <li
      v-if="enableFold"
      :class="[$style.arrow, { [$style.expanded]: !opened }]"
      @click="toggle"
    >
      <img src="@/assets/images/icons/navigation/big-arrow.svg">
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    hiddenText: {
      type: String,
      required: false,
    },
    tabStyle: {
      type: Object,
      required: false,
    },
    hideArrow: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      current: null,
    };
  },
  computed: {
    enableFold() {
      if (this.hideArrow) return false;
      return true;
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style lang="scss" module>
.tabs {
  padding: 0 5px 0 5px;
  background-color: $light-gray;
  &:before,
  &:after {
    display: table;
    content: ' ';
    clear: both;
  }

  .arrow {
    height: 50px;
    margin-right: 20px;
    background-color: $light-gray;
    margin-top: 5px;
    float: right;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .expanded {
    transform: rotate(180deg);
  }

  .hidden {
    height: 50px;
    margin-right: 20px;
    background-color: $light-gray;
    margin-top: 5px;
    float: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 25px;
  }

  .linkContainer {
    height: 50px;
    display: block;
    background-color: $light-gray;
    margin-top: 5px;
    margin-bottom: -1px;
    float: left;
    cursor: pointer;
    &.active {
      color: $black;
      background-color: #fff;
      &:before {
        opacity: 0;
      }
      & + li:before {
        opacity: 0;
      }
    }
    &:not(.active) > a {
      &:hover {
        background-color: #eee;
      }
      &:focus {
        text-decoration: none;
        background-color: #fff;
      }
    }
    &:not(:first-child):before {
      content: '|';
      font-size: 25px;
      color: #d3d3d3;
    }
  }

  .link {
    display: inline-block;
    font-style: normal;
    font-weight: 300;
    line-height: 18.75px;
    height: 50px;
    text-decoration: none;
    color: $black;

    @media (max-width: 1831px) {
      font-size: 11px;
      padding: 14px 10px 17px 10px;
    }
    @media (min-width: 1831px) {
      font-size: 16px;
      padding: 14px 20px 17px 20px;
    }
  }
}
</style>
